import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { IndexPageGatsbyCDAContainer } from '@containers/Index'

const IndexOfItemsPage = ({ pageContext, data }) => {
  let { id, locale, slug, seo } = pageContext

  const metaTitle = seo?.[0]?.title || 'Case Studies'
  const metaDescription =
    seo?.[0]?.excerpt ||
    'space.camp is a full-service agency dedicated to exploring the frontiers of brand storytelling.'

  const defaultShareImage =
    '//images.ctfassets.net/00d5npkzl3wz/3KLK5fiRh6tPbDAf78EX1g/cf6d5f1bc97e7cbedc8dd62f5cb1d412/spacecamp_thumbnail.jpg'

  const seoObj = {
    id,
    locale,
    metaTitle,
    metaDescription,
    slug,
    articleSEO: false,
    pageSEO: true,
    ogImage: {
      fixed: {
        width: 1200,
        height: 627,
        src: defaultShareImage,
      },
    },
  }

  return (
    <Layout {...pageContext} seo={seoObj}>
      <IndexPageGatsbyCDAContainer
        {...pageContext}
        items={data?.items?.nodes}
      />
    </Layout>
  )
}
export default IndexOfItemsPage

export const query = graphql`
  query IndexOfItemsPage(
    $skip: Int!
    $limit: Int!
    $locale: String!
    $relatedArticles: [String!]
  ) {
    items: allContentfulArticle(
      skip: $skip
      limit: $limit
      filter: {
        contentful_id: { in: $relatedArticles }
        node_locale: { eq: $locale }
      }
      sort: {
        fields: [publicationDate, author___id, createdAt, title]
        order: [DESC, ASC]
      }
    ) {
      nodes {
        ...relatedArticleFields
      }
    }
  }
`
